import React , {useRef}from 'react'
import './about.css'
import aswin from '../../assets/aswin-2.jpg'
import {data} from './data'
import { motion, useScroll, useTransform } from 'framer-motion'
// import { useRef } from 'react'

// import {FaAward} from 'react-icons/fa'
// import {FiUsers} from 'react-icons/fi'
// import {VscFolderLibrary} from 'react-icons/vsc'


const About = () => {
  const container = useRef(null)
  const {scrollYProgress} = useScroll({
      target:container,
      offset: ['start end', 'end start']
  })
  const sm = useTransform(scrollYProgress, [0 , 1 ], [0, -5])
  const md = useTransform(scrollYProgress, [0 , 1 ], [0, -100])
  const lg = useTransform(scrollYProgress, [0 , 2 ], [0, -250])
  return (
    <section ref={container} id='about'>
      <motion.h1 style={{y:sm}}>Get To Know</motion.h1>
      <h2>About Me</h2>

      <div  className="container about-container">
        <div className="about-me">
          <div className="about-me-img">
            <motion.img  src={aswin} alt="About Image" />
          </div>
        </div>
        <div className="about-content">
          <div className="about-cards">
            {data.map((item)=> (
                <article key={item.id} className="about-card">
                  {item.icon}
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </article>
            ))}
          </div>
          <p>
          I'm a creative web developer with a strong background in graphic design, dedicated
           to crafting visually stunning and user-friendly websites. I specialize in modern 
           web technologies to create responsive and scalable solutions across devices. From
            sleek portfolios to complex web apps,
           I focus on delivering results that drive success. Let's collaborate and bring your
            digital vision to life!
              .</p>
              <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About