import {BiCheck} from 'react-icons/bi'
import './services.css'

export const data3 = [
    {id:1, desc:"A flair for innovative and original design concepts.", icon: <BiCheck className='service-list-icon'/>},
    {id:2, desc:"A keen eye for precision and perfection.", icon: <BiCheck className='service-list-icon'/>},
    {id:3, desc:"Effective storytelling through visuals.", icon: <BiCheck className='service-list-icon'/>},
    {id:4, desc:"Proficient in Illustrator, Photoshop, and XD.", icon: <BiCheck className='service-list-icon'/>},
    {id:5, desc:"Skillful at finding creative solutions for design challenges.", icon: <BiCheck className='service-list-icon'/>},
    // {id:6, desc:"Generating Portfolio Items from Array using", icon: <BiCheck className='service-list-icon'/>},
    // {id:7, desc:"Generating Portfolio Items from Array using", icon: <BiCheck className='service-list-icon'/>},
    // {id:8, desc:"Generating Portfolio Items from Array using", icon: <BiCheck className='service-list-icon'/>},
    // {id:9, desc:"Generating Portfolio Items from Array using", icon: <BiCheck className='service-list-icon'/>},


    
]