import React from 'react'
import './experience.css'
import { data1 } from './data1'
import { data2 } from './data2'
import { data3 } from './data3'



function Experience() {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience-container">
        <div className="experience-1">
          <h3>Front End Development</h3>
          <div className="experience-content">
            {data1.map((item)=>(
              <article className='experience-details'>
                <div>
                {item.icon}
                <h4>{item.skill}</h4>
                <small className='text-light'>{item.level}</small>
                </div>
              </article>
            ))}
          </div>
        </div>
        <div className="experience-1">
          <h3>Back End Development</h3>
          <div className="experience-content">
            {data2.map((item)=>(
              <article className='experience-details'>
               <div>
               {item.icon}
                <h4>{item.skill}</h4>
                <small className='text-light'>{item.level}</small>
               </div>
              </article>
            ))}
          </div>
        </div>
        <div className="experience-1">
          <h3>Design Tools</h3>
          <div className="experience-content">
            {data3.map((item)=>(
              <article className='experience-details'>
               <div>
               {item.icon}
                <h4>{item.skill}</h4>
                <small className='text-light'>{item.level}</small>
               </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience