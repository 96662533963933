import {BiCheck} from 'react-icons/bi'

export const data2 = [
    {id:1, desc:"Prioritizing user needs to create intuitive and engaging interfaces.", icon: <BiCheck className='service-list-icon'/>},
    {id:2, 
        desc:"Crafting interfaces that seamlessly adapt to various screen sizes and devices.", 
        icon: <BiCheck className='service-list-icon'/>},
    // {id:3,
    //      desc:" Ensuring meticulous attention to detail for flawless designs.", icon: <BiCheck className='service-list-icon'/>},
    {id:4, desc:"Delivering consistent experiences across different web browsers.", icon: <BiCheck className='service-list-icon'/>},
    {id:5, desc:"Writing clean and organized code for optimal performance.", icon: <BiCheck className='service-list-icon'/>},
    // {id:6, desc:"Utilizing React's power to build scalable and modular UI components.", icon: <BiCheck className='service-list-icon'/>},
    {id:7, desc:"Tackling challenges with innovative and effective solutions.", icon: <BiCheck className='service-list-icon'/>},
    {id:8, desc:" Contributing ideas and expertise to foster productive teamwork.", icon: <BiCheck className='service-list-icon'/>},
    {id:9, desc:"Staying updated with the latest UI trends and emerging technologies.", icon: <BiCheck className='service-list-icon'/>},


    
]