import React , {useRef} from 'react'
import './header.css'
import CTA from './CTA'
import aswin from '../../assets/asw-dev.png'
import HeaderSocials from './HeaderSocials'
import { motion, useScroll, useTransform } from 'framer-motion'



const Header = () => {
  const container = useRef(null)
  const {scrollYProgress} = useScroll({
      target:container,
      offset: ['start end', 'end start']
  })
  const sm = useTransform(scrollYProgress, [0 , 1 ], [0, -10])
  const md = useTransform(scrollYProgress, [0 , 1 ], [0, -100])
  const lg = useTransform(scrollYProgress, [0 , 2 ], [0, -250])
  return (
    <header>
        <div ref={container} className="container header-container">
            <motion.h5 style={{y:sm}}>Hi</motion.h5>
            <motion.h1  animate={{ x: 10 }} transition={{ ease: "easeOut", duration: 3 }}  className="animation" >I'm Aswin Dev</motion.h1>
            <h5 className="text-light">Web Developer</h5>
            <CTA/>
            {/* <HeaderSocials/> */}
            {/* <div className="me">
                <img src={aswin} alt="" className='me-img'  />
            </div> */}
            <a href="#contact" className='scroll-down'>Scroll Down</a>
        </div>
    </header>
  )
}

export default Header