import {BsPatchCheckFill} from 'react-icons/bs'

export const data2 = [
    // {id:1,skill: "Blazer",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:2,skill: "Node JS",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:3,skill: "MySql",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:4,skill: "Strapi",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:5,skill: "Mongo DB",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    // {id:6,skill: "Python ",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},

]
