import {BiCheck} from 'react-icons/bi'

export const data1 = [
    {id:1, desc:"User Interface (UI) Design", icon: <BiCheck className='service-list-icon'/>},
    {id:2, desc:"User Experience (UX) Design", icon: <BiCheck className='service-list-icon'/>},
    {id:3, desc:"Wireframing and Prototyping", icon: <BiCheck className='service-list-icon'/>},
    {id:4, desc:"Responsive Web Design", icon: <BiCheck className='service-list-icon'/>},
    {id:5, desc:"Mobile App Design", icon: <BiCheck className='service-list-icon'/>},
    {id:6, desc:"Interaction Design", icon: <BiCheck className='service-list-icon'/>},
    {id:7, desc:"Information Architecture", icon: <BiCheck className='service-list-icon'/>},
    // {id:8, desc:" Website  From Scratch- Build Deploy  JS  Website", icon: <BiCheck className='service-list-icon'/>},
    // {id:9, desc:" Website  From Scratch- Build Deploy  JS  Website", icon: <BiCheck className='service-list-icon'/>},
]

