import React from 'react'
import './portfolio.css'
import { data } from './data'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio-container">
        {data.map((item)=>(
          <article className="portfolio-item">
            <div className="portfolio-item-img">
              {item.img}
            </div>
            <h3>{item.title}</h3>
            <div className="portfolio-item-cta">
               <a href="https://github.com" className='btn' target='_button'>{item.git}</a>
               <a href={item.sitelink} target='_blank' className='btn btn-primary'>{item.demo}</a>
            </div>
          </article>
        ))}
      </div>
    </section>
  )
}

export default Portfolio