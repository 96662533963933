import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

export const data = [
    {id:1, title: 'Experience',desc:"2+ Years of Working" , icon: <FaAward className='about-icon'/>},
    // {id:2, title: 'Clients',desc:"100+ Worldwide" , icon: <FiUsers className='about-icon'/>},
    {id:3, title: 'projects',desc:"10+ Completed" , icon: <VscFolderLibrary className='about-icon'/>},


]