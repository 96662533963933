import {BsPatchCheckFill} from 'react-icons/bs'

export const data1 = [
    {id:1,skill: "HTML",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:10,skill: "CSS",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:2,skill: "JavaScript",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:3,skill: "BOOTSTRAP",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:4,skill: "Tailwind",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:5,skill: "ReactJS",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:6,skill: "NextJS",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:7,skill: "Three js",level:"Fresher",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:8,skill: "Framer motion",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:9,skill: "Unity 3D",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},

]

