import React,{useRef, useState} from 'react'
import './contact.css'
import { data } from './data'
import emailjs from 'emailjs-com'

const Contact = () => {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [msg,setMsg] = useState("");
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    e.target.reset();
    emailjs.sendForm('service_1gssnlv', 'template_6usv17e', form.current, '4AzBLsMf22UC5_6qK')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact-container">
        <div className="contact-options">
          {data.map((item)=>(
            <article className="contact-option">
              {item.icon}
              <h4>{item.name}</h4>
              <h5>{item.mail}</h5>
              <a href={item.href} target='_blank'>Send a Message</a>
            </article>
          ))}
        </div>
        <form ref={form} onSubmit={sendEmail} action=''>
          <input type="text" name='name' onChange={(e)=>setName(e.target.value)} placeholder='Your Full Name' required/>
          <input type="email" name='email' onChange={(e)=>setEmail(e.target.value)} placeholder='Your Email' required/>
          <textarea name="message" onChange={(e)=>setMsg(e.target.value)}  rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>

  )
}

export default Contact