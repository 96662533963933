import React from 'react'
import './services.css'
import { data1 } from './data1'
import { data2 } from './data2'
import { data3 } from './data3'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container service-container">
        <article className="service">
          <div className="service-head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className='service-list'>
          {data1.map((item)=>(
            <li>
              {item.icon}
              <p>{item.desc}</p>
            </li>
          ))}
          </ul>
        </article>
        <article className="service">
          <div className="service-head">
            <h3>Web  Development</h3>
          </div>
          <ul className='service-list'>
          {data2.map((item)=>(
            <li>
              {item.icon}
              <p>{item.desc}</p>
            </li>
          ))}
          </ul>
        </article>
        <article className="service">
          <div className="service-head">
            <h3>Graphic Design</h3>
          </div>
          <ul className='service-list'>
          {data3.map((item)=>(
            <li>
              {item.icon}
              <p>{item.desc}</p>
            </li>
          ))}
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services