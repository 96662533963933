import {BsPatchCheckFill} from 'react-icons/bs'

export const data3 = [
    {id:1,skill: "Adobe Illustrator",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:2,skill: "Abobe Photoshop",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:3,skill: "Adobe XD",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    {id:4,skill: "Figma",level:"Fresher",icon:<BsPatchCheckFill className='experience-details-icon'/>},
    // {id:5,skill: "React",level:"Experienced",icon:<BsPatchCheckFill className='experience-details-icon'/>},

]

