import React from 'react'
import './footer.css'
import {BsFacebook} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer-logo'></a>
      <ul className="links">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer-socials">
        <a href="https://www.facebook.com/aswin.devsr"><BsFacebook/></a>
        <a href="https://www.instagram.com/aswin__dev/"><BsInstagram/></a>
        <a href="https://twitter.com/calicutgooner"><BsTwitter/></a>
      </div>
      <div className="footer-copyright">
        <small>© 2024 Aswin Dev.</small>
      </div>
    </footer>
  )
}

export default Footer